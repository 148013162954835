import React from "react";
import FormItem, { FormItemProps } from "./FormItem";

type Props = React.InputHTMLAttributes<HTMLInputElement> & FormItemProps;

const Checkbox: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ id, label, description, ...others }, ref) => {
    return (
      <FormItem
        id={id}
        className="mb-4 flex items-center"
        description={description}
        required={others.required}
      >
        <input
          ref={ref}
          id={id}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          {...others}
        />
        {label && (
          <label
            htmlFor={id}
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {label}
          </label>
        )}
        {description && (
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            {description}
          </p>
        )}
      </FormItem>
    );
  }
);
Checkbox.displayName = "Input";

export default Checkbox;
