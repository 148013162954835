import React from "react";
import Layout from "@components/layout";
import Title from "@components/UI-Elements/Typography/Title";
import SEO from "@components/seo";
import { Section } from "@components/UI-Elements/General";
import Tag from "@components/UI-Elements/Tag/Tag";
import Button from "@components/UI-Elements/Button";
import Checkbox from "@components/UI-Elements/Form/Checkbox";
import { useTheme } from "@common/hooks/useTheme";

const title = "Design System";
const Page = () => {
  const { darkMode, toggle } = useTheme();
  
  return (
    <Layout contentWidth="full" prose>
      <SEO title={title} robots="noindex" />
      <Title>{title}</Title>
      <Checkbox id="checkbox" label="Checkbox" onChange={() => toggle()} checked={darkMode} />

      <Section>
        <Title level={2}>Tags</Title>
        <div className="flex flex-wrap uppercase">
          <Tag color="blue" clickable>blue</Tag>
          <Tag color="amber" clickable>amber</Tag>
          <Tag color="dark" clickable>dark</Tag>
          <Tag color="green" clickable>green</Tag>
          <Tag color="indigo" clickable>indigo</Tag>
          <Tag color="pink" clickable>pink</Tag>
          <Tag color="purple" clickable>purple</Tag>
          <Tag color="red" clickable>red</Tag>
          <Tag color="violet" clickable>violet</Tag>
          <Tag color="yellow" clickable>yellow</Tag>
        </div>
      </Section>

      <Section>
        <Title level={2}>Buttons</Title>
        <div className="flex flex-wrap justify-around mb-4">
          <Button color="blue">blue</Button>
          <Button color="default">default</Button>
          <Button color="dark">dark</Button>
          <Button color="green">green</Button>
          <Button color="purple">purple</Button>
          <Button color="red">red</Button>
          <Button color="yellow">yellow</Button>
        </div>
        <Title level={2}>Buttons Outline</Title>
        <div className="flex flex-wrap justify-around">
          <Button color="blue" variant="outline">blue</Button>
          <Button color="default" variant="outline">default</Button>
          <Button color="dark" variant="outline">dark</Button>
          <Button color="green" variant="outline">green</Button>
          <Button color="purple" variant="outline">purple</Button>
          <Button color="red" variant="outline">red</Button>
          <Button color="yellow" variant="outline">yellow</Button>
        </div>
      </Section>
    </Layout>
  );
};

export default Page;
