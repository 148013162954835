import useDarkMode from "use-dark-mode";

export const useTheme = (initialState = false) => {
  const _document = typeof document === "undefined" ? null : document;

  const { toggle, value } = useDarkMode(initialState, {
    classNameDark: "dark",
    classNameLight: "light",
    element: _document && _document?.documentElement,
  });

  return { toggle, darkMode: value };
};
